<template>
  <layout
    :activeIndex="activeIndex"
    :activeModel.sync="activeModel"
    :topBarItems="topBarItems"
    @setIndex="setIndex"
  >
    <!--    <template #top>-->
    <!--      <b-row>-->
    <!--        <b-col v-for="(v, i) in mode" :key="i" cols="2">-->
    <!--          <b-alert show variant="secondary">-->
    <!--            <div class="alert-body d-flex align-items-center">-->
    <!--              <i :class="v.icon" class="mr-1" style="font-size: 2rem"></i><span>{{ v.title }}</span>-->
    <!--            </div>-->
    <!--          </b-alert>-->
    <!--        </b-col>-->
    <!--      </b-row>-->
    <!--    </template>-->

    <template #left-0>
      <left-menu
        :floors="floors"
        :system="system"
        @select="leftMenuSelect"
      ></left-menu>
    </template>

    <template #left-1>
      <b-card>
        <b-card-title>筛选</b-card-title>
        <label>设备名称</label>
        <b-form-input
          placeholder="输入设备名称查询"
          v-model="equipFilter.equip_name"
          trim
        ></b-form-input>

        <label class="mt-1">楼层</label>
        <v-select
          append-to-body
          clearable
          multiple
          v-model="equipFilter.floor"
          :options="equipFilterOptions.floor"
          :placeholder="$t('No filter')"
          class="w-100"
        />

        <div class="text-right mt-2">
          <b-btn class="mr-1" @click="equipFilterReset">重置</b-btn>
          <b-btn variant="primary" @click="equipFilterSubmit">查询</b-btn>
        </div>
      </b-card>
    </template>

    <template #left-2>
      <!-- <b-card>
                            <label>分组选择</label>
                            <b-form-select v-model="selected_group" :options="options_group" value-field="text"></b-form-select>

                            <label class="mt-1">日程选择</label>
                            <b-form-select v-model="selected_calendar" :options="options_calendar" value-field="text"></b-form-select>

                            <div class="pt-2 float-right">
                              <b-button variant="primary">一键设定</b-button>
                            </div>
                          </b-card> -->
    </template>

    <template #right-1>
      <b-card no-body>
        <b-table
          :current-page="currentPage"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          hover
          responsive
          striped
          show-empty
        >
          <template v-slot:empty="scope">
            <div class="my-5">
              <h4 class="text-center">{{ $t("No Data") }}</h4>
            </div>
          </template>
          <template v-slot:cell(params)="data">
            <template v-for="(param, param_index) in data.item.params">
              <span
                v-if="param.param_name"
                :style="param.cur_value === null ? 'opacity:0.3' : ''"
              >
                <span class="mx-50" v-if="param_index !== 0">,</span>
                <span>{{
                  param.param_name.replace(data.item.equip_name + "-", "")
                }}</span
                >:
                <span v-if="param.cur_value === null">N/A</span>
                <span v-else>{{
                  param.unit && param.unit.indexOf("||") > -1
                    ? param.unit.split("||")[param.cur_value > 0 ? 1 : 0]
                    : param.cur_value + " " + (param.unit || "")
                }}</span>
              </span>
            </template>
          </template>
          <template #cell(action)="data">
            <div class="text-center">
              <b-button v-if="data.item.ip_address" variant="warning" size="sm"
                >查看
              </b-button>
              <b-button
                variant="success"
                size="sm"
                @click="equip_to_detail_model(data.item)"
                :disabled="!data.item.detail_model_key"
                >详情
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        align="right"
        first-number
        hide-goto-end-buttons
        last-number
        next-class="next-item"
        prev-class="prev-item"
      />
    </template>

    <template #right-2>
      <b-row>
        <b-col cols="12">
          <b-card no-body>
            <b-table
              :fields="mode_fields"
              :items="mode_items"
              hover
              responsive
              striped
            >
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template v-slot:cell(action)="data">
                <b-button
                  v-b-modal:air-condition-mode-run-time
                  size="sm"
                  variant="primary"
                  >详情
                </b-button>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-modal
        id="air-condition-mode-run-time"
        :no-close-on-backdrop="true"
        size="lg"
        centered
        hide-footer
      >
        <div class="p-2" style="height: 22rem">
          <b-row class="mt-1" no-gutters v-for="(item, index) in 7" key="item">
            <b-col cols="2" class="text-center">
              周{{ week_days[index] }}
            </b-col>
            <b-col cols="10">
              <b-progress :max="24" show-value style="height: 1.5rem">
                <template v-if="index < 5">
                  <b-progress-bar
                    label="00:00~09:00"
                    :value="9"
                    variant="secondary"
                  ></b-progress-bar>
                  <b-progress-bar
                    label="09:00~14:00"
                    :value="14"
                    variant="success"
                  ></b-progress-bar>
                  <b-progress-bar
                    label="14:00~17:00"
                    :value="17"
                    variant="secondary"
                  ></b-progress-bar>
                  <b-progress-bar
                    label="17:00~20:00"
                    :value="20"
                    variant="success"
                  ></b-progress-bar>
                  <b-progress-bar
                    label="20:00~24:00"
                    :value="24"
                    variant="secondary"
                  ></b-progress-bar>
                </template>
              </b-progress>
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </template>

    <template #right-3>
      <panel-group :show-title="false" group-uid="5e9adbef"></panel-group>
    </template>

    <b-modal
      id="modal-scoped"
      :no-close-on-backdrop="true"
      :title="
        action_index === 0 ? '新建组合式空调机组日程' : '编辑组合式空调机组日程'
      "
      cancel-title="取消"
      cancel-variant="outline-secondary"
      centered
      ok-title="确定"
    >
      <template #default="{ hide }">
        <b-row class="my-1">
          <b-col cols="6">
            <span>日程名称</span>
          </b-col>
          <b-col cols="6">
            <b-form-input id="data_name"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="6">
            <span>开始日期</span>
          </b-col>
          <b-col cols="6">
            <b-form-input id="start_date" type="date"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="6">
            <span>结束日期</span>
          </b-col>
          <b-col cols="6">
            <b-form-input id="end_date" type="date"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="6">
            <span>开启时间</span>
          </b-col>
          <b-col cols="6">
            <b-form-input id="start_time" type="time"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="6">
            <span>关闭时间</span>
          </b-col>
          <b-col cols="6">
            <b-form-input id="close_time" type="time"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="6">
            <span>送风温度</span>
          </b-col>
          <b-col cols="6">
            <b-input-group append="℃">
              <b-form-input></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="6">
            <span>送风温度</span>
          </b-col>
          <b-col cols="6">
            <b-input-group append="℃">
              <b-form-input></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="6">
            <span>CO2浓度</span>
          </b-col>

          <b-col cols="6">
            <b-input-group append="ppm">
              <b-form-input></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </layout>
</template>

<script>
import { reactive, ref, toRefs, watch } from "@vue/composition-api";
import Layout from "@/views/front/jsf35/subsystems/layout";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import store from "@/store";
import vSelect from "vue-select";

export default {
  name: "air-conditioning-ventilation",
  components: {
    vSelect,
    Layout,
    LeftMenu,
    panelGroup: () => import("@/views/panel-group"),
  },
  setup() {
    // 框架相关参数
    const layoutParams = reactive({
      activeIndex: 0,
      activeModel: "",
      topBarItems: [
        {
          title: "主页",
          model: {
            path: "ventilation_system/ventilation/ventilation",
            dbIds: ["equipment_"],
            light_switch: {},
          },
        },
        { title: "设备列表" },
        { title: "模式管理" },
        { title: "数据分析" },
      ],
    });
    //左侧菜单相关参数
    const leftMenuParams = reactive({
      floors: [
        {
          title: "RF",
          value: 13,
          model: {
            path: "ventilation_system/ventilation_RF/ventilation_RF",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
          },
        },
        {
          title: "F12",
          value: 12,
          model: {
            path: "ventilation_system/ventilation_F11/ventilation_F11",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXF11", "LineF11", "Plane011"],
          },
        },
        {
          title: "F11",
          value: 11,
          model: {
            path: "ventilation_system/ventilation_F10/ventilation_F10",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXF10", "LineF10", "Plane010"],
          },
        },
        {
          title: "F10",
          value: 10,
          model: {
            path: "ventilation_system/ventilation_F9/ventilation_F9",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXF9", "LineF9", "Plane009"],
          },
        },
        {
          title: "F9",
          value: 9,
          model: {
            path: "ventilation_system/ventilation_F8/ventilation_F8",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXF8", "LineF8", "Plane008"],
          },
        },
        {
          title: "F8",
          value: 8,
          model: {
            path: "ventilation_system/ventilation_F7/ventilation_F7",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXF7", "equipment_AHUF7", "LineF7", "Plane007"],
          },
        },
        {
          title: "F7",
          value: 7,
          model: {
            path: "ventilation_system/ventilation_F6/ventilation_F6",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXF6", "LineF6", "Plane006"],
          },
        },
        {
          title: "F6",
          value: 6,
          model: {
            path: "ventilation_system/ventilation_F5/ventilation_F5",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXF5", "equipment_AHUF5", "LineF5", "Plane005"],
          },
        },
        {
          title: "F5",
          value: 5,
          model: {
            path: "ventilation_system/ventilation_F4/ventilation_F4",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXF4", "LineF4", "Plane004"],
          },
        },
        {
          title: "F3",
          value: 3,
          model: {
            path: "ventilation_system/ventilation_F3/ventilation_F3",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXF3", "LineF3", "Plane003"],
          },
        },
        {
          title: "F2",
          value: 2,
          model: {
            path: "ventilation_system/ventilation_F2/ventilation_F2",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXF2", "equipment_AHUF2", "LineF2", "Plane002"],
          },
        },
        {
          title: "F1",
          value: 1,
          model: {
            path: "ventilation_system/ventilation_F1/ventilation_F1",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXF01", "LineF01", "Plane001"],
          },
        },
        {
          title: "B1",
          value: -1,
          model: {
            path: "ventilation_system/ventilation_B1/ventilation_B1",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXB1", "equipment_PFB1", "equipment_SFB1", "jsf_B1_Wall", "jsf_B1_floor"],
          },
        },
        {
          title: "B2",
          value: -2,
          model: {
            path: "ventilation_system/ventilation_B2/ventilation_B2",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUXB2", "equipment_AHUB2", "equipment_BFB2", "equipment_PFB2", "equipment_SFB2", "equipment_YDB2", "jsf_B2_wall", "jsf_B2_floor"],
          },
        },
        {
          title: "B3",
          value: -3,
          model: {
            path: "ventilation_system/ventilation_B3/ventilation_B3",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_AHUB3", "equipment_BFB3", "equipment_PFB3", "equipment_SFB3", "equipment_YDB3", "jsf_B3_Wall", "jsf_B3_floor"],
          },
        },
        {
          title: "B5",
          value: -5,
          model: {
            path: "ventilation_system/ventilation_B5/ventilation_B5",
            dbIds: ["equipment_"],
            // showDbIds: ["equipment_BFB4", "equipment_PFB4", "equipment_SFB4", "equipment_YDB4", "jsf_b4_wall", "jsf_b4_floor"],
          },
        },
      ],
      system: true,
    });
    // 左侧菜单选中事件
    const leftMenuSelect = (event) => {
      console.log("leftMenuSelectEvent", event);
      if (event?.equip_name) {
        event.model.isDetail = true;
        layoutParams.activeModel = event.model;
      } else {
        event.model.isDetail = false;
        layoutParams.activeModel = event.model;
      }
    };

    const setIndex = (e) => {
      layoutParams.activeIndex = e;
    };
    const data = reactive({
      index: 0,
      flag: false,
      week_days: ["一", "二", "三", "四", "五", "六", "日"],
      list: [
        { title: "组合式空调机组", number: [24, 18, 1] },
        { title: "新风机组", number: [12, 10, 0] },
        { title: "风机盘管", number: [44, 38, 3] },
        { title: "送风机组", number: [24, 18, 2] },
      ],

      selected_calendar: "冬季工作日",
      options_calendar: [
        {
          text: "冬季工作日",
        },
        {
          text: "冬季节假日",
        },
        {
          text: "夏季工作日",
        },
        {
          text: "夏季节假日",
        },
        {
          text: "过渡季工作日",
        },
        {
          text: "过渡季节假日",
        },
      ],
      selected_season: [
        { value: "请选择" },
        { value: "请选择" },
        { value: "请选择" },
        { value: "请选择" },
      ],
      options_season: [
        {
          text: "请选择",
        },
        {
          text: "冬季工作日",
        },
        {
          text: "冬季节假日",
        },
        {
          text: "夏季工作日",
        },
        {
          text: "夏季节假日",
        },
        {
          text: "过渡季工作日",
        },
        {
          text: "过渡季节假日",
        },
      ],
      status: [
        {
          text: "主页",
          active: true,
        },
        {
          text: "设备列表",
          active: false,
        },
        {
          text: "模式管理",
          active: false,
        },
      ],
      mode: [
        {
          icon: "iconfont icon-sound-module-line",
          title: "冬季工作日模式",
        },
        {
          icon: "iconfont icon-sound-module-line",
          title: "冬季节假日模式",
        },
        {
          icon: "iconfont icon-sound-module-line",
          title: "夏季工作日模式",
        },
        {
          icon: "iconfont icon-sound-module-line",
          title: "夏季节假日模式",
        },
        {
          icon: "iconfont icon-sound-module-line",
          title: "过渡季工作日模式",
        },
        {
          icon: "iconfont icon-sound-module-line",
          title: "过渡季节假日模式",
        },
        /* {
                                          icon: "iconfont icon-sound-module-line",
                                          title: "手动运行模式",
                                      }, */
      ],
      nodes: [
        {
          name: "组合式空调机组",
          open: true,
          children: [
            {
              name: "组合式空调机组-001",
            },
            {
              name: "组合式空调机组-002",
            },
            {
              name: "组合式空调机组-003",
            },
            {
              name: "组合式空调机组-004",
            },
            {
              name: "组合式空调机组-005",
            },
          ],
        },
        {
          name: "新风机组",
          open: true,
          children: [
            {
              name: "新风机组-001",
            },
            {
              name: "新风机组-002",
            },
            {
              name: "新风机组-003",
            },
            {
              name: "新风机组-004",
            },
            {
              name: "新风机组-005",
            },
          ],
        },
        {
          name: "风机盘管",
          open: true,
          children: [
            {
              name: "风机盘管-001",
            },
            {
              name: "风机盘管-002",
            },
            {
              name: "风机盘管-003",
            },
            {
              name: "风机盘管-004",
            },
          ],
        },
        {
          name: "送风机组",
          open: true,
          children: [
            {
              name: "送风机组-001",
              path: "https://cabr-jsf-1255710621.cos.ap-beijing.myqcloud.com/model/air_plant/intake_air_plant/air_plant.svf",
            },
            {
              name: "送风机组-002",
            },
            {
              name: "送风机组-003",
            },
            {
              name: "送风机组-004",
            },
          ],
        },
        {
          name: "排风机组",
          open: true,
          children: [
            {
              name: "排风机组-001",
              path: "https://cabr-jsf-1255710621.cos.ap-beijing.myqcloud.com/model/air_plant/exhaust_air_plant/air_plant.svf",
            },
            {
              name: "排风机组-002",
            },
            {
              name: "排风机组-003",
            },
            {
              name: "排风机组-004",
            },
          ],
        },
        {
          name: "DDC",
          open: true,
          children: [
            {
              name: "DDC-001",
            },
            {
              name: "DDC-002",
            },
            {
              name: "DDC-003",
            },
            {
              name: "DDC-004",
            },
          ],
        },
      ],
      units: ["组合式空调机组", "新风机组", "风机盘管", "送风机组", "排风机组"],
      // 设备列表
      fields: [
        { label: "ID", key: "equip_id" },
        { label: "设备名称", key: "equip_name" },
        { label: "设备编号", key: "equip_meter_code" },
        { label: "楼层", key: "floor" },
        { label: "参数状态", key: "params" },
        { label: "操作", key: "action" },
      ],
      items: [],
      perPage: 20,
      currentPage: 1,
      rows: 1,
      action: [
        {
          text: "新建日程",
        },
        {
          text: "编辑日程",
        },
      ],
      action_index: 0,
      mode_fields: [
        { key: "index", label: "ID" },
        { key: "mode_name", label: "模式名称" },
        { key: "equip_name", label: "设备名称" },
        { key: "location", label: "位置" },
        { key: "action", label: "操作" },
      ],
      mode_items: [
        {
          mode_name: "新风时间表",
          equip_name: "整体",
          location: "南楼",
        },
        {
          mode_name: "新风时间表",
          equip_name: "整体",
          location: "北楼",
        },
        {
          mode_name: "空调时间表",
          equip_name: "整体",
          location: "二层",
        },
        {
          mode_name: "空调时间表",
          equip_name: "整体",
          location: "五层",
        },
        {
          mode_name: "空调时间表",
          equip_name: "整体",
          location: "七层",
        },
        {
          mode_name: "空调时间表",
          equip_name: "整体",
          location: "十一层",
        },
        {
          mode_name: "热回收时间表",
          equip_name: "RHS-2-1",
          location: "南楼",
        },
        {
          mode_name: "热回收时间表",
          equip_name: "RHS-2-2",
          location: "南楼",
        },
        {
          mode_name: "热回收时间表",
          equip_name: "RHS-1-1",
          location: "北楼",
        },
        {
          mode_name: "热回收时间表",
          equip_name: "RHS-1-2",
          location: "北楼",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B1-1",
          location: "地下一层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B1-1-1",
          location: "地下一层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B1-2",
          location: "地下一层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B1-3",
          location: "地下一层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B1-A4",
          location: "地下一层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B1-Z1",
          location: "地下一层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B1-Z3",
          location: "地下一层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B1-Z5",
          location: "地下一层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B1-Z6",
          location: "地下一层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B1-Z7",
          location: "地下一层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B1-Z8",
          location: "地下一层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B2-1-1",
          location: "地下二层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B2-1-2",
          location: "地下二层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B2-2",
          location: "地下二层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B2-3-1",
          location: "地下二层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B2-3-2",
          location: "地下二层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B2-Z1",
          location: "地下二层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B2-Z2",
          location: "地下二层",
        },
        {
          mode_name: "新风时间表",
          equip_name: "AHU(X)-B3-7",
          location: "地下三层",
        },
      ],
      mode_select: 0,
      mode_perPage: 15,
      mode_currentPage: 1,
      mode_rows: 1,
    });

    watch(
      () => store.state.jsf35.equip_list,
      (list) => {
        data.items = list;
        data.rows = list.length;
      }
    );

    //设备列表跳转到模型大样图
    const equip_to_detail_model = (item) => {
      setIndex(0);
      setTimeout(() => {
        store.commit("jsf35/setEquipSelected", item);
        leftMenuSelect(item);
      }, 1000);
    };

    const changeStatus = ({ v, i }) => {
      console.log(v, i);
      data.nodes[i].open = !v;
    };
    watch(
      () => data.index,
      (value) => {
        if (value === 3) {
        }
      }
    );
    /**
     * 设备列表查询
     * @type {Ref<UnwrapRef<{equip_name: string, floor: string}>>}
     */
    const equipFilter = ref({
      equip_name: "",
      floor: "",
    });
    const equipFilterOptions = ref({
      floor: [
        "RF",
        "F12",
        "F11",
        "F10",
        "F9",
        "F8",
        "F7",
        "F6",
        "F5",
        "F3",
        "F2",
        "F1",
        "B1",
        "B2",
        "B3",
        "B5",
      ],
    });

    const equipFilterSubmit = () => {
      const { equip_name, floor } = equipFilter.value;
      data.items = store.state.jsf35.equip_list.filter((item) => {
        return (
          (equip_name
            ? item.equip_name.toLowerCase().includes(equip_name.toLowerCase())
            : true) && (floor ? floor.includes(item.floor) : true)
        );
      });
      data.rows = data.items.length;
    };

    const equipFilterReset = () => {
      console.log("equipFilterReset");
      equipFilter.value = {
        equip_name: "",
        floor: "",
      };
      equipFilterSubmit();
    };

    return {
      ...toRefs(layoutParams),
      ...toRefs(leftMenuParams),
      leftMenuSelect,
      setIndex,
      ...toRefs(data),
      changeStatus,
      equip_to_detail_model,
      equipFilter,
      equipFilterOptions,
      equipFilterSubmit,
      equipFilterReset,
    };
  },
};
</script>

<style lang="scss" scoped>
.types {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
}

.bold-text {
  font-weight: bolder;
  font-size: 1.5rem;
}

.span-text {
  font-size: 1.6rem;
  line-height: 1.5rem;
}
</style>
